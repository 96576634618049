import * as React from "react";
import { FormikProps } from "formik";
import numeral from "numeral";
import useLocale from "../../contexts/locale";

interface IProps {
  formik: FormikProps<{
    amount: number;
    duration: number;
    interest: number;
    type: string;
  }>;
}

type Record = {
  amount: number;
  interest: number;
  payment?: number;
  remaining?: number;
};

const pmt = (ir: number, np: number, pv: number, fv = 0, mode: 0 | 1 = 0) => {
  /*
   * ir   - interest rate per month
   * np   - number of periods (months)
   * pv   - present value
   * fv   - future value
   * mode - when the payments are due:
   *        0: end of the period, e.g. end of month (default)
   *        1: beginning of period
   */
  let pmt;

  if (ir === 0) {
    return -(pv + fv) / np;
  }

  const pvif = Math.pow(1 + ir, np);
  pmt = (-ir * pv * (pvif + fv)) / (pvif - 1);

  if (mode === 1) pmt /= 1 + ir;

  return pmt;
};

const formatNumber = (value?: number, format = "0,0.00"): string => {
  if (!value || value < 0 || isNaN(value) || Math.floor(value) === 0)
    return "0";
  return numeral(value).format(format);
};

const CalculatorTable = ({ formik }: IProps) => {
  const { locale, i18n } = useLocale();
  const i18nData = (i18n as any)[locale];

  const [rows, setRows] = React.useState<Record[]>([]);
  const [totalPayment, setTotalPayment] = React.useState(0);
  const [totalInterest, setTotalInterest] = React.useState(0);
  const [paymentPerMonth, setPaymentPerMonth] = React.useState(0);

  const calculateTotalEqual = () => {
    const duration = formik.values.duration;
    let remaining = formik.values.amount;
    const monthlyInterest = formik.values.interest / 100;
    const records = [];
    let _payment = 0;
    let _interest = 0;

    for (let m = 0; m < duration; m++) {
      const record: Record = {
        amount: formik.values.amount / duration,
        interest: remaining * monthlyInterest,
      };

      record.payment = record.amount + record.interest;
      remaining = remaining - record.amount;

      record.remaining = remaining;
      _payment += record.payment;
      _interest += record.interest;
      records.push(record);
    }

    setRows(records);
    setTotalPayment(_payment);
    setTotalInterest(_interest);
    setPaymentPerMonth(records.length > 0 ? records[0].payment || 0 : 0);
  };
  const calculateMonthlyEqual = () => {
    const duration = formik.values.duration;
    let remaining = formik.values.amount;
    const records = [];
    const monthlyInterest = formik.values.interest / 100;
    const monthlyPayment = pmt(
      monthlyInterest,
      duration,
      formik.values.amount * -1
    );
    let _payment = 0;
    let _interest = 0;

    for (let m = 0; m < duration; m++) {
      const record: Record = {
        amount: monthlyPayment,
        interest: remaining * monthlyInterest,
        payment: monthlyPayment,
      };
      record.amount = monthlyPayment - record.interest;
      remaining = remaining - record.amount;
      record.remaining = remaining;

      _payment += record["payment"] || monthlyPayment;
      _interest += record.interest;
      records.push(record);
    }

    setRows(records);
    setTotalPayment(_payment);
    setTotalInterest(_interest);
    setPaymentPerMonth(records.length > 0 ? records[0].payment || 0 : 0);
  };

  React.useEffect(() => {
    if (formik.values.type === "1") {
      calculateMonthlyEqual();
    }
    if (formik.values.type === "2") {
      calculateTotalEqual();
    }
  }, [
    formik.values.amount,
    formik.values.duration,
    formik.values.interest,
    formik.values.type,
  ]);

  const totalRow = {
    amount: 0,
    interest: 0,
    payment: 0,
    balance: 0,
  };

  return (
    <>
      <div className="pt-10 pb-10 pl-10 pr-10 mb-15 loan-stat">
        <div className="row">
          <div
            className="col-sm-12 col-md-8 mb-15"
            dangerouslySetInnerHTML={{
              __html: i18nData.loan[
                formik.values.type === "1"
                  ? "equalMonthlyPaymentDescription"
                  : "equalMonthlyLoanPaymentDescription"
              ]
                .replace(
                  "{0}",
                  `<span>${formatNumber(formik.values.amount)}</span>`
                )
                .replace("{1}", `<span>${formik.values.interest}</span>`)
                .replace("{2}", `<span>${formik.values.duration}</span>`)
                .replace(
                  "{3}",
                  `<span>${formatNumber(paymentPerMonth)}</span>`
                ),
            }}
          ></div>
        </div>
      </div>

      <div className="shadow rounded pt-10 pb-10 pl-10 pr-10">
        <table className="table table-hover table-sm table-bordered rounded text-right">
          <thead style={{ backgroundColor: "rgb(9, 107, 216)", color: "#fff" }}>
            <tr>
              <th style={{ width: "10%" }}>{i18nData.loan.month}</th>
              <th style={{ width: "25%" }}>{i18nData.loan.monthlyPayment}</th>
              <th style={{ width: "20%" }}>{i18nData.loan.interestPayment}</th>
              <th style={{ width: "20%" }}>
                {i18nData.loan.totalAmountOfPayment}
              </th>
              <th style={{ width: "25%" }}>{i18nData.loan.balance}</th>
            </tr>
          </thead>
        </table>
        <div style={{ maxHeight: "60vh", overflowY: "scroll" }}>
          <table className="table table-hover table-sm table-bordered rounded text-right">
            <tbody>
              {rows.reduce((acc: React.ReactElement[], row, index) => {
                totalRow.amount += row.amount;
                totalRow.interest += row.interest;
                totalRow.payment += row.payment || 0;
                acc.push(
                  <tr key={index}>
                    <td style={{ width: "10%" }}>{index + 1}</td>
                    <td style={{ width: "25%" }}>{formatNumber(row.amount)}</td>
                    <td style={{ width: "20%" }}>
                      {formatNumber(row.interest)}
                    </td>
                    <td style={{ width: "20%" }}>
                      {formatNumber(row.payment || 0)}
                    </td>
                    <td style={{ width: "25%" }}>
                      {formatNumber(row.remaining)}
                    </td>
                  </tr>
                );

                return acc;
              }, [])}
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <strong style={{ color: "#000" }}>{i18nData.total}</strong>
                </td>
                <td>
                  <strong style={{ color: "#000" }}>
                    {formatNumber(totalRow.amount)}
                  </strong>
                </td>
                <td>
                  <strong style={{ color: "#000" }}>
                    {formatNumber(totalRow.interest)}
                  </strong>
                </td>
                <td>
                  <strong style={{ color: "#000" }}>
                    {formatNumber(totalRow.payment)}
                  </strong>
                </td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <style>
        {`
          .loan-stat span {
            color: rgb(9, 107, 216)
          }
        `}
      </style>
    </>
  );
};

export default CalculatorTable;
