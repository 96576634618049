import * as React from "react";
import { graphql } from "gatsby";
import { useFormik } from "formik";

import Layout from "../components/Layout";
import CalculatorForm from "../components/Calculator/form";
import CalculatorTable from "../components/Calculator/table";
import useLocale from "../contexts/locale";
import SEO from "../components/Seo";

interface IProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
  location: Location;
  pageContext: { locale: "mn-mn" | "en-us" };
}

const Calculator = () => {
  const { locale, i18n } = useLocale();
  const i18nData = (i18n as any)[locale];

  const formik = useFormik({
    initialValues: {
      amount: 1000000,
      duration: 6,
      interest: 2.8,
      type: "1",
    },
    onSubmit: (values: any) => {
      console.log(values);
    },
  });
  return (
    <div className="container pt-50 pb-130">
      <div className="text-center mb-50">
        <h1>{i18nData.calculator}</h1>
      </div>
      <div className="row">
        <div className="col-md-3 col-sm-12">
          <CalculatorForm formik={formik} />
        </div>
        <div className="col-md-9 col-sm-12">
          <CalculatorTable formik={formik} />
        </div>
      </div>
    </div>
  );
};

const CalculatorPage = (props: IProps) => {
  const { data } = props;
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout {...props} title={siteTitle}>
      <SEO title={`Loan calculator`} />
      <Calculator />
    </Layout>
  );
};

export default CalculatorPage;

export const pageQuery = graphql`
  query CalculatorQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
