import * as React from "react";
import { FormikProps } from "formik";
import useLocale from "../../contexts/locale";

interface IProps {
  formik: FormikProps<{
    amount: number;
    duration: number;
    interest: number;
    type: string;
  }>;
}

const CalculatorForm = ({ formik }: IProps) => {
  const { locale, i18n } = useLocale();
  const i18nData = (i18n as any)[locale];

  return (
    <div>
      <div className="form-group">
        <label htmlFor="amount">{i18nData.loan.amount}</label>
        <div className="input-group">
          <div className="input-group-prepend">
            <div className="input-group-text">₮</div>
          </div>
          <input
            type="number"
            className="form-control"
            id="amount"
            value={formik.values.amount}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.value) {
                formik.handleChange("amount")(e);
              } else {
                formik.handleChange("amount")("1");
              }
            }}
          />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="duration">{i18nData.loan.term}</label>
        <input
          type="number"
          className="form-control"
          id="duration"
          value={formik.values.duration}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (parseInt(e.target.value, 10) > 360) {
              formik.handleChange("duration")("360");
            } else if (e.target.value) {
              formik.handleChange("duration")(e);
            } else {
              formik.handleChange("duration")("1");
            }
          }}
        />
      </div>
      <div className="form-group">
        <label htmlFor="interest">{i18nData.loan.rate}</label>
        <div className="input-group">
          <div className="input-group-prepend">
            <div className="input-group-text">%</div>
          </div>
          <input
            type="number"
            className="form-control"
            id="interest"
            min="0.1"
            step="0.1"
            value={formik.values.interest}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (parseFloat(e.target.value) > 50) {
                formik.handleChange("interest")("50");
              } else if (e.target.value) {
                formik.handleChange("interest")(e);
              } else {
                formik.handleChange("interest")("0");
              }
            }}
          />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="type">{i18nData.loan.repayment}</label>
        <select
          className="form-control"
          id="type"
          value={formik.values.type}
          onChange={formik.handleChange("type")}
        >
          <option value="1">{i18nData.loan.equalMonthlyPayment}</option>
          <option value="2">{i18nData.loan.equalMonthlyLoanPayment}</option>
        </select>
      </div>
    </div>
  );
};

export default CalculatorForm;
